.home__header {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  align-items: center;
}

.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.home__header a {
  margin-right: 20px;
  text-decoration: inherit;
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
}

.home__header a:hover {
  text-decoration: underline;
}

.home__body > img {
  object-fit: contain;
  height: 150px;
}

.home__body {
  flex: 1;
  display: flex;
  margin-top: 10%;
  flex-direction: column;
}
